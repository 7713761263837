@import "@radix-ui/themes/styles.css";

/* Import Geist fonts */
@import url("https://fonts.googleapis.com/css2?family=Geist:wght@400;500;600;700&family=Geist+Mono:wght@400;500;600;700&display=swap");

/* Import Inter font */
/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"); */

.radix-themes {
  --default-font-family: "Geist", system-ui;
  --heading-font-family: "Geist", system-ui;
  --code-font-family: "Geist Mono", monospace;
  --strong-font-family: "Geist", system-ui;
  --em-font-family: "Geist", system-ui;
  --quote-font-family: "Geist", system-ui;
}

/* Add some base styles */
body {
  margin: 0;
  min-height: 100vh;
  background-color: var(--accent-1);
}
